var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialogSummary,"max-width":"720","persistent":"","fullscreen":_vm.$viewport.width < 450}},[_c('v-card',{attrs:{"color":"#061422"}},[_c('v-card-text',{attrs:{"id":"card-text"}},[(!_vm.multiplayer)?_c('v-row',{attrs:{"justify":"center"}},[_c('p',{attrs:{"id":"summary-text"},domProps:{"innerHTML":_vm._s(
                        _vm.$t('DialogSummary.summaryMsgSinglePoints', {
                            points: _vm.points,
                        }) +
                            (_vm.showDistance
                                ? _vm.$t('DialogSummary.summaryMsgSingleDistance', {
                                    distance: _vm.score / 1000,
                                })
                                : '')
                    )}})]):_vm._e(),_vm._l((_vm.summaryTexts),function(text,index){return _c('v-row',{key:index,staticClass:"mt-3",attrs:{"justify":"center"}},[_c('span',{attrs:{"id":"summary-text"}},[(index == 0 || index == 1 || index == 2)?_c('v-icon',{attrs:{"color":index == 0
                                ? '#CCAC00'
                                : index == 1
                                    ? '#C0C0C0'
                                    : '#CC8E34'}},[_vm._v("mdi-crown")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.$t('DialogSummary.summaryMsgMultiPoints', {
                                playerName: text.playerName,
                                points: text.finalPoints,
                            }) +
                                (_vm.showDistance
                                    ? _vm.$t(
                                        'DialogSummary.summaryMsgMultiDistance',
                                        {
                                            distance: text.finalScore / 1000,
                                        }
                                    )
                                    : '')
                        )}})],1)])}),_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mt-8",attrs:{"id":"play-again-button","dark":"","color":"#43B581"},on:{"click":function($event){return _vm.$emit('finishGame')}}},[_vm._v(" "+_vm._s(_vm.$t('DialogSummary.viewDetails'))+" ")]),(!_vm.multiplayer)?_c('v-btn',{staticClass:"mt-8",attrs:{"id":"exit-button","dark":"","color":"#f44336"},on:{"click":function($event){return _vm.$emit('playAgain')}}},[_vm._v(" "+_vm._s(_vm.$t('Maps.playAgain'))+" ")]):_vm._e()],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }