var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-areas",attrs:{"id":"map"}},[_c('div',{staticClass:"map-content"},[(_vm.polygonSelect || _vm.areaName)?_c('div',{staticClass:"map-label",attrs:{"title":_vm.areaName}},[(_vm.showFlag)?_c('FlagIcon',{attrs:{"size":"big","rounded":"","iso-name":_vm.areaRandom || _vm.areaNameSelect}}):_vm._e(),_c('span',{staticClass:"map-label__country-name",class:{
                    beige: !_vm.areaRandom,
                    green: !!_vm.areaRandom,
                    'white--text': !!_vm.areaRandom,
                    large: !_vm.showFlag,
                }},[_vm._v(" "+_vm._s(_vm.areaName)+" ")])],1):_vm._e(),_c('GmapMap',{ref:"mapRef",attrs:{"id":"mapAreas","center":{ lat: 37.86926, lng: -122.254811 },"zoom":1,"map-type-id":"roadmap","options":{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                draggableCursor: 'crosshair'
            }}})],1),(_vm.infoWindowDatas.length > 0)?_c('div',{staticClass:"result-panel"},_vm._l((_vm.infoWindowDatas),function(info){return _c('div',{key:info.playerName,staticClass:"result-panel__item",class:{ flag: _vm.showFlag }},[(_vm.showFlag)?_c('FlagIcon',{attrs:{"iso-name":info.area}}):_vm._e(),_c('b',[_vm._v(_vm._s(info.playerName))]),(!_vm.showFlag)?_c('span',[_vm._v(_vm._s(info.area))]):_vm._e()],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }